<template>
  <div class="brands">

    <NavHeader />
    <div class="content_container content">
      <div class="banner">

        <img
          class="banner04"
          v-for='(item,index) in banner'
          :key='index'
          :src="$config.baseUrl+item.get_files.files_path"
          alt=""
        />
      </div>

      <div class="shop-list">

        <ul>
          <li
            class="item"
            v-for="(item, index) in shopList"
            :key="index"
          >
            <div
              class="card"
              :style="{background:'url('+$config.baseUrl+item.shop_ad_img+')'}"
            >

              <!-- <div class="tips"><span v-if='item.shop_type==2'>品牌供应商</span><span v-if="item.shop_type==3">4s旗舰店</span></div> -->
              <div class="bg">
                <div class="c">

                  <img
                    v-if='item.shop_logo'
                    :src=" item.shop_logo"
                  />
                  <img
                    v-else
                    src="../../assets/images/tx.jpg"
                    alt=""
                  />
                  <p class="p1">{{item.shop_name}}</p>

                </div> <span @click="$util.toShop(item.shop_type, item.shop_id )" class="hover">
                  进店
                </span>
              </div>

            </div>
            <dl
              v-if='!item.get_goods.length'
              style='height:160px;'
            >
              <el-empty
                image-size='110'
                style='padding:0'
                description="暂无商品"
              ></el-empty>
            </dl>
            <dl v-else>
              <dd
                v-for='(o,i) in item.get_goods'
                :key="i"
                  @click="$util.toDetail(o.shop_type, o.goods_id )"
              >
                <img
                  :src='$config.baseUrl+o.good_url'
                 >
                <div
                  class="item-price"
                  v-if="o.isprice == 2"
                >
                  <span>￥{{ parseFloat(o.min_price).toFixed(2) }}</span>
                  -
                  <span>￥{{ parseFloat(o.max_price).toFixed(2) }}</span>
                </div>

                <div
                  class="item-price"
                  v-if="o.isonly == 1 && o.isprice == 1"
                >

                  <span>￥{{
                        parseFloat(o.sales_price/ 100).toFixed(2) 
                      }}</span>
                </div>
                <div
                  class="item-price"
                  v-if="o.isonly == 2 && o.isprice == 1"
                >
                  <span>￥{{ o.spec_min_price}}
                    起</span>
                </div>

              </dd>
            </dl>
          </li>
        </ul>
      </div>
<el-pagination
        @current-change="currentChange"
        :current-page.sync="searchData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="searchData.limit"
        :total="total"
      >
      </el-pagination>
    </div>
   
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      shopList: [],
      total: 0,
      banner: [],
      hotGoods: [],
      searchData: {
        brand_id: "",
        catid: "",
        shop_type: 2,
        page: 1,
        limit: 10
      }
    };
  },
  computed: {},
  mounted() {
    this.getBanner();
    this.currentChange();
  },
  methods: {
    getBanner() {
      this.$get("home/ad", { pid: 541 }).then(res => {
        this.banner = res || [];
      });
    },
    toDetail(e) {
      this.$router.push({
        path: "/detail-supplier",
        query: { id: e.goods_id }
      });
    },

    currentChange() {
      this.$get("home/shop",  this.searchData).then(res => {
        res.items.data.forEach(item => {
          item.get_goods = item.get_goods.filter((o, i) => {
            return i < 3;
          });
        });
        this.shopList = res.items.data;
        this.total = res.items.total;
      });
    }
  },
  components: {
    NavHeader
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.card {
  margin-bottom: 20px;
  .bg {
    padding: 10px;
   height: 70px;
    background: rgba($color: #000000, $alpha: 0.5);
    @include space-between;
    .c {
      @include row;
      flex: 1;
      img {
        width: 135px;
        height: 50px;
      }

      .p1 {
        color: #fff;
        padding-top: 10px;
        padding-left: 20px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    span {
      border-radius: 20px;
      border: 1px solid #fff;
      text-align: center;
      width: 60px;
      height: 25px;
      line-height: 25px;
      color: #fff;
      margin-top: 10px;
    }
  }
}
.item-price {
  width: 100%;
  padding: 10px 0;
  text-align: left;
  span {
    font-size: 14px;
    color: red;
    font-weight: bold;
    text-align: left;
  }
}
.banner {
  width: 100%;
  height: 350px;
  img {
    width: 100%;
    height: 100%;
  }
}
.shop-list {
  display: flex;
  ul:after {
    content: "";
    display: block;
    clear: both;
  }
  ul {
    margin: 0 -20px;
    flex: 1;
    display: flex;
    padding: 10px;
    overflow: hidden;

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      background: #fff;
      float: left;
      width: 580px;
      margin: 0 15px;
      // background: #f0f0f0;
      border: 1px solid #eee;
      margin-top: 10px;
      box-sizing: border-box;

      padding: 20px;

      dl {
        flex: 1;
        margin: 0 -5px;
        @include space-between;
        dd {
          box-sizing: border-box;
          background: #fff;

          width: 160px;
          margin: 0 5px;
          height: 200px;
          word-break: break-all;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          img {
            width: 160px;
            height: 160px;
          }
          p {
            font-size: 16px;
            line-height: 35px;
            font-weight: bold;
            span {
              color: red;
            }
            width: 160px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
 